import MemberList from "./views/base/tables/MemberList";
import GrandLodge from "./views/base/tables/GrandLodge";
import LocalLodge from "./views/base/tables/LocalLodge";
import Dashboard from "./views/dashboard/Dashboard";
import Users from "./views/users/Users";
import User from "./views/users/User";
import { Profile } from "./views/pages/Profile/Profile";
import InboxList from "./views/base/tables/Inbox";
import MediaList from "./views/base/tables/Media";
import UserDetail from "./views/pages/users/detail";
import Events from "./views/base/tables/Events";
import NotificationsList from "./views/base/tables/Notifications";
import GroupDetail from "./views/pages/group/group-detail";
import Reports from "./views/base/tables/Reports";
import GroupsList from "./views/base/tables/Groups";
import ResourceList from "./views/base/tables/Resources";
import ResourceCategoryList from "./views/base/tables/ResourcesCategories";

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/users/:userId', name: 'Detail', component: UserDetail },
  { path: '/users', name: 'Users', component: MemberList },
  { path: '/grandlodge', name: 'Grand Lodge', component: GrandLodge },
  { path: '/locallodge', name: 'Local Lodge', component: LocalLodge },
  { path: '/groups', name: 'Groups', component: GroupsList },
  { path: '/users', exact: true, name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },
  { path: '/profile', exact: true, name: 'Profile', component: Profile },
  { path: '/inbox', exact: true, name: 'Inbox', component: InboxList },
  { path: '/media', exact: true, name: 'Media', component: MediaList },
  { path: '/events', exact: true, name: 'Events', component: Events },
  { path: '/notifications', exact: true, name: 'Notifications', component: NotificationsList },
  { path: '/group-detail/:groupId', name: 'Group Detail', component: GroupDetail },
  { path: '/report', name: 'Not Registered', component: Reports },
  { path: '/resource-categories', name: 'Resource Categories', component: ResourceCategoryList },
  { path: '/resources', name: 'Resources', component: ResourceList },
];

export default routes;
