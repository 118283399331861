import { authHeader } from '../helpers';
const BASE_URL = "https://api.masonicsprig.com/";

export const localLodgeService = {
    getLocalLodge,
    addLocalLodge,
    updateLocalLodge,
    deleteLocalLodge
};

function getLocalLodge() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/local-lodges`, requestOptions).then(function (data) {
        return data.json();
    });
}

function addLocalLodge(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/local-lodges`, requestOptions).then(function (data) {
        return data.json();
    });
}

function updateLocalLodge(formdata, localLodgeId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/local-lodges/${localLodgeId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function deleteLocalLodge(localLodgeId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/local-lodges/${localLodgeId}`, requestOptions).then(function (data) {
        return data.json();
    });
} 