import React from 'react'
import {
    CModalFooter,
    CModalBody,
    CModalHeader,
    CModal,
    CButton,
    CForm,
    CInput,
    CInputGroup,
    CRow,
    CCol,
    CSpinner,
    CLabel,
    CTextarea
} from '@coreui/react'
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import { eventsService } from "../../store/Services";


const EventsModal = (props) => {
    const { closeHandler, model, submitHandler, onChange, eventData, isLoading, isUpdate, mediaList = [], onChangeStartDateTime, onChangeEndDateTime, userList=[] } = props;

    const imageOptions = [];
    mediaList.forEach(media => {
        imageOptions.push({ value: media.image, label: <div><img style={{width: 30, height: 30, borderRadius: '50%', marginRight: 10}} src={`${eventsService.BASE_URL}${ media.image}`} /> {' '} {media.title} </div>,  });
    })

    return (
        <>
            <CModal
                show={model}
                onClose={closeHandler}
                size="lg"
                closeOnBackdrop={!isLoading}
            >
                <CForm onSubmit={submitHandler}>
                    <CModalHeader closeButton>{isUpdate ? 'Update' : 'Add'} Event</CModalHeader>
                    <CModalBody>
                        <CRow>
                            <CCol md="6">
                                <CLabel htmlFor="eventTitle">Event Title</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Event Title"
                                        onChange={onChange}
                                        name="eventTitle"
                                        value={eventData.eventTitle}
                                        id="eventTitle"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="eventStockImage">Event Image</CLabel>
                                <CInputGroup className="mb-3">
                                    <Select
                                        style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }} 
                                        name="eventStockImage" 
                                        id="eventStockImage"  
                                        required 
                                        onChange={(e_)=>{ onChange({target: {name: 'eventStockImage', value: e_.value}}) }}
                                        value={imageOptions.find(option => option.value === eventData.eventStockImage)}
                                        options={imageOptions}
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="eventStreetAddress">Event Address</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Event Address"
                                        onChange={onChange}
                                        name="eventStreetAddress"
                                        value={eventData.eventStreetAddress}
                                        id="eventStreetAddress"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="eventCity">Event City</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Event City"
                                        onChange={onChange}
                                        name="eventCity"
                                        value={eventData.eventCity}
                                        id="eventCity"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="eventState">Event State</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Event State"
                                        onChange={onChange}
                                        name="eventState"
                                        value={eventData.eventState}
                                        id="eventState"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="eventZipcode">Event Zip Code</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Event Zipcode"
                                        onChange={onChange}
                                        name="eventZipcode"
                                        value={eventData.eventZipcode}
                                        id="eventZipcode"
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="eventStartTime">Event Start Date/Time</CLabel>
                                <CInputGroup className="mb-3">
                                    <DateTimePicker
                                        required
                                        id="eventStartTime"
                                        name="eventStartTime"
                                        onChange={onChangeStartDateTime}
                                        value={eventData.eventStartTime}
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="eventEndTime">Event End Date/Time</CLabel>
                                <CInputGroup className="mb-3">
                                    <DateTimePicker
                                        required
                                        id="eventEndTime"
                                        name="eventEndTime"
                                        onChange={onChangeEndDateTime}
                                        value={eventData.eventEndTime}
                                    />
                                </CInputGroup>
                            </CCol>

                            <CCol md="6">
                                <CLabel htmlFor="publishToUsers">Publish to Users</CLabel>
                                <CInputGroup className="mb-3">
                                    <Select
                                        style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }} 
                                        name="publishToUsers" 
                                        id="publishToUsers"
                                        className="select-box"  
                                        required
                                        onChange={(e_)=>{ onChange({target: {name: 'publishToUsers', value: e_.map(user => user.value)}}) }}
                                        isMulti={true}
                                        options={userList}
                                        value={ userList.filter(item => eventData.publishToUsers.includes(item.value)) }
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="organisers">Organisers</CLabel>
                                <CInputGroup className="mb-3">
                                    <Select
                                        style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }} 
                                        name="organisers" 
                                        id="organisers"
                                        className="select-box"  
                                        required
                                        onChange={(e_)=>{ onChange({target: {name: 'organisers', value: e_.map(user => user.value)}}) }}
                                        isMulti={true}
                                        options={userList}
                                        value={ userList.filter(item => eventData.organisers.includes(item.value)) }
                                    />
                                </CInputGroup>
                            </CCol>
                            
                            <CCol md="12">
                                <CLabel htmlFor="eventDescription">Event Description</CLabel>
                                <CInputGroup className="mb-3">
                                    <CTextarea
                                        placeholder="Event Description"
                                        onChange={onChange}
                                        name="eventDescription"
                                        value={eventData.eventDescription}
                                        id="eventDescription"
                                    />
                                </CInputGroup>
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="primary" type="submit" disabled={isLoading}> {isUpdate ? 'Update' : 'Add'} {isLoading ? <CSpinner color="info" size="sm" /> : ""}</CButton>
                    </CModalFooter>
                </CForm>
            </CModal>
        </>
    )
}

export default EventsModal;
