import React from 'react'
import {
  CModalFooter,
  CModalBody,
  CModalHeader,
  CModal,
  CButton,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CSpinner
} from '@coreui/react'

import CIcon from '@coreui/icons-react';


const UploadModel = (props) => {
  const {closeHandler , model, submitHandler, selectFile, isLoading} = props;
 
    return (
      <>
        <CModal
          show={model}
          onClose={closeHandler}
        >
          <CModalHeader closeButton>Upload Users Data</CModalHeader>
          <CModalBody>
            <CInputGroup className="mb-3">
                <CInputGroupPrepend>
                <CInputGroupText>
                    <CIcon name="cil-user" />
                </CInputGroupText>
                </CInputGroupPrepend>
                <CInput 
                type="file" 
                placeholder="Users Data" 
                onChange={selectFile}
                name="memberdata" />
            </CInputGroup>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type ="button" onClick={submitHandler} disabled={isLoading}> Upload {isLoading ? <CSpinner color="info" size="sm" /> : ""}</CButton>
          </CModalFooter>
        </CModal>
      </>
    )
}

export default UploadModel
