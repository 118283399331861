import React from 'react'
import {
    CModalFooter,
    CModalBody,
    CModalHeader,
    CModal,
    CButton,
    CForm,
    CInput,
    CInputGroup,
    CRow,
    CCol,
    CSpinner,
    CLabel,
    CTextarea
} from '@coreui/react'


const GrandLodgeModal = (props) => {
    const { closeHandler, model, submitHandler, onChange, lodgeData, isLoading, isUpdate, grandLodges = [], isLocalLodge = false, isGroup = false } = props;

    return (
        <>
            <CModal
                show={model}
                onClose={closeHandler}
                size="lg"
                closeOnBackdrop={!isLoading}
            >
                <CForm onSubmit={submitHandler}>
                    <CModalHeader closeButton>{isUpdate ? 'Update' : 'Add'} {isGroup ? 'Group' : 'Grand Lodge'}</CModalHeader>
                    <CModalBody>
                        <CRow>
                            <CCol md="6">
                                <CLabel htmlFor="name">Name</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Name"
                                        onChange={onChange}
                                        name="name"
                                        value={lodgeData.name}
                                        id="name"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            {
                                (isLocalLodge || isGroup) ?
                                    <CCol md="6">
                                        <CLabel htmlFor="parentId">Parent Lodge</CLabel>
                                        <CInputGroup className="mb-3">
                                            <select
                                                name="parentId"
                                                id="parentId"
                                                style={{ width: "100%", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }}
                                                required={!isGroup}
                                                onChange={onChange}
                                                value={lodgeData.parentId || ""}>
                                                <option value="">-- Select Parent Lodge --</option>
                                                {grandLodges.map(lodge => (<option value={lodge.id}>{lodge.name}</option>))}
                                            </select>
                                        </CInputGroup>
                                    </CCol> : ''
                            }
                            <CCol md="6">
                                <CLabel htmlFor="address">Address</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Address"
                                        onChange={onChange}
                                        name="address"
                                        value={lodgeData.address}
                                        id="address"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="city">City</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="City"
                                        onChange={onChange}
                                        name="city"
                                        value={lodgeData.city}
                                        id="city"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="state">State</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="state"
                                        onChange={onChange}
                                        name="state"
                                        value={lodgeData.state}
                                        id="state"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="state">Region</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Region"
                                        onChange={onChange}
                                        name="region"
                                        value={lodgeData.region}
                                        id="region"
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="zipcode">Zip Code</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Zipcode"
                                        onChange={onChange}
                                        name="zipcode"
                                        value={lodgeData.zipcode}
                                        id="zipcode"
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="phone">Phone</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="number"
                                        placeholder="Phone"
                                        onChange={onChange}
                                        name="phone"
                                        value={lodgeData.phone}
                                        id="phone"
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="website">Website</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Website"
                                        onChange={onChange}
                                        name="website"
                                        value={lodgeData.website}
                                        id="website"
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="12">
                                <CLabel htmlFor="meetingSchedule">Meeting Schedule</CLabel>
                                <CInputGroup className="mb-3">
                                    <CTextarea
                                        placeholder="Meeting Schedule"
                                        onChange={onChange}
                                        name="meetingSchedule"
                                        value={lodgeData.meetingSchedule}
                                        id="meetingSchedule"
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="12">
                                <CLabel htmlFor="messageTitle">Message Title </CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Message Title"
                                        onChange={onChange}
                                        name="messageTitle"
                                        value={lodgeData.messageTitle}
                                        id="messageTitle"
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="12">
                                <CLabel htmlFor="message">Message</CLabel>
                                <CInputGroup className="mb-3">
                                    <CTextarea
                                        placeholder="Message"
                                        onChange={onChange}
                                        name="message"
                                        value={lodgeData.message}
                                        id="message"
                                    />
                                </CInputGroup>
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="primary" type="submit" disabled={isLoading}> {isUpdate ? 'Update' : 'Add'} {isLoading ? <CSpinner color="info" size="sm" /> : ""}</CButton>
                    </CModalFooter>
                </CForm>
            </CModal>
        </>
    )
}

export default GrandLodgeModal;
