import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton
} from '@coreui/react'

import { eventsService, mediaService, notificationService } from "../../../store/Services"
import EventsModal from "../../../common/Model/EventsModal"
import ConfirmBox from "../../../common/Model/ConfirmBox"
import moment from 'moment'

const fields = [{ key: 'id', label: 'ID' }, 'image', { key: 'eventTitle', label: 'Title',  _style: { width: '18%' } }, { key: 'eventDescription', label: 'Description', _style: { width: '25%' }, }, { key: 'eventStartTime', label: 'Date', _style: { width: '15%' }, }, {key: 'createdBy', label: 'Creator', _style: { width: '13%' }},  'action'];
const defaultEventsData = { id: '', eventTitle: '', eventDescription: '', eventStockImage: null, eventDate: '', eventStartTime: '', eventEndTime: '', eventStreetAddress: '', eventCity: '', eventState: '', eventZipcode: '', publishToUsers: [], organisers: []};

const Events = () => {

  const [events, setEventsList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [usersList, setUsersList] = useState([]);



  const [eventsModal, setModal] = useState(false);
  const [confirmModel, setConfirmModel] = useState({ modelState: false, data: {} });
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [eventData, setEvents] = useState(defaultEventsData);

  useEffect(() => {
    fetchData();
    fetchMediaList();
  }, []);

  const fetchData = async () => {
    const result = await eventsService.getEvents();
    result.events = result.events.map(e_ => {
        e_.eventStartTime = e_.eventStartTime ? new Date(e_.eventStartTime) : new Date();
        e_.eventEndTime = e_.eventEndTime ? new Date(e_.eventEndTime) : new Date();
        e_.eventDate = e_.eventDate ? new Date(e_.eventDate) : new Date();
        e_.publishToUsers = e_.eventToCalendars ? e_.eventToCalendars.map(item => item.userId) : [];
        e_.organisers = e_.eventOrganisers ? e_.eventOrganisers.map(item => item.userId) : [];
        return e_;
    })
    const usersData = await notificationService.getActiveUsers();
    if (usersData.users) {
      let users = usersData.users.map(user => ({label: (user.firstName+" "+user.lastName+" "+`(${user.email})`), value: user.userId}));
      setUsersList(users);
    }
    setEventsList(result.events);
  };

  const fetchMediaList = async () => {
    const result = await mediaService.Media();
    setMediaList(result.allMedia);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { id, eventTitle, eventDescription, eventStockImage, eventDate, eventStartTime, eventEndTime,  eventStreetAddress, eventCity, eventState, eventZipcode, organisers, publishToUsers} = eventData;
    // const formData = new FormData();
    // formData.append('eventTitle', eventTitle);
    // formData.append('eventDescription', eventDescription);
    // if(eventStockImage) formData.append('eventStockImage', eventStockImage);
    // formData.append('eventDate', eventDate);
    // formData.append('eventStartTime', eventStartTime);
    // formData.append('eventEndTime', eventEndTime);
    // formData.append('eventStreetAddress', eventStreetAddress);
    // formData.append('eventCity', eventCity);
    // formData.append('eventState', eventState);
    // formData.append('eventZipcode', eventZipcode);
    const formData = {
      eventTitle,
      eventDescription,
      eventDate,
      eventStartTime,
      eventEndTime,
      eventStreetAddress,
      eventCity,
      eventState,
      eventZipcode,
      organisers,
      publishToUsers
    }

    if(eventStockImage){
      formData.eventStockImage = eventStockImage;
    }

    if (id) {
      eventsService.updateEvents(formData, id).then(async () => {
        await fetchData();
        setIsLoading(false);
        setModal(false);
      }).catch(e => {
        setIsLoading(false);
      })
    } else {
      eventsService.addEvents(formData).then(async () => {
        await fetchData();
        setIsLoading(false);
        setModal(false);
      }).catch(e => {
        setIsLoading(false);
      })
    }
  }

  const deleteHandler = () => {
    setIsLoading(true);
    eventsService.deleteEvents(confirmModel.data.id).then(async () => {
      await fetchData();
      setIsLoading(false);
      setConfirmModel(false);
    }).catch(e => {
      setIsLoading(false);
    })
  }

  const eventModalHandler = (item) => {
    if (item) {
      setEvents(item);
      setIsUpdate(true);
    } else {
      setEvents(defaultEventsData)
      setIsUpdate(false);
    }
    setModal(!eventsModal)
  }

  const onChange = ({ target: { name, value } }) => {
    setEvents({
      ...eventData, [name]: value
    })
  }

  const onChangeStartDateTime = (event) => {
    setEvents({
    ...eventData, ['eventDate']: event, ['eventStartTime']: event
    })
  }
  
  const onChangeEndDateTime = (event) => {
    setEvents({
    ...eventData, ['eventEndTime']: event
    })
  }

  return (
    <>
      <CRow>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-between">
                <CCol xs="6" lg="6">Events</CCol>
                <CCol xs="6" lg="6">
                  <div className="text-right">
                    <CButton
                      color="primary"
                      onClick={() => eventModalHandler()}
                      className="px-4" >
                      Add
                    </CButton>
                  </div>
                  {eventsModal && <EventsModal
                    closeHandler={() => {
                      setModal(false);
                    }}
                    model={eventsModal}
                    isUpdate={isUpdate}
                    submitHandler={submitHandler}
                    onChange={onChange}
                    onChangeStartDateTime={onChangeStartDateTime}
                    onChangeEndDateTime={onChangeEndDateTime}
                    eventData={eventData}
                    mediaList={mediaList}
                    isLoading={isLoading}
                    isEvents={true}
                    userList={usersList}
                  />}
                  <ConfirmBox
                    closeHandler={() => { setConfirmModel({ data: {}, modelState: false }) }}
                    model={confirmModel.modelState}
                    submitAction={deleteHandler}
                    headerTitle={"Delete Event"}
                    message={"Are you sure to delete it?"}
                    isLoading={isLoading}
                  />
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={events.map(member => {
                  for (let key in member) {
                    member[key] = member[key] || "";
                  }
                  return member;
                })}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                sorter
                tableFilter
                scopedSlots={{
                  'action':
                    (item) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              eventModalHandler(item)
                            }}
                            className="mr-2"
                          >
                            Edit
                            </CButton>
                          <CButton
                            color="danger"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setConfirmModel({ modelState: true, data: item })
                            }}
                          >
                            Delete
                            </CButton>
                        </td>
                      )
                    },
                    'image': (item)=>{
                        return (
                            <td>
                              <a href={`${eventsService.BASE_URL}${ item.eventStockImage ? item.eventStockImage : 'public/default-placeholder-image.png'}`} target="_blank">
                                <img src={`${eventsService.BASE_URL}${ item.eventStockImage ? item.eventStockImage : 'public/default-placeholder-image.png'}`} style={{width: '80px', height: '80px'}}/>
                              </a>
                            </td>
                        )
                    },
                    'eventStartTime': (item)=>{
                        return (
                            <td>
                              {moment(item.eventStartTime).format('DD/MM/YY hh:mm A')}
                            </td>
                        )
                    },
                    'createdBy': (item)=>{
                      return (
                          <td>
                            { item.user ? `${item.user.firstName || ""} ${item.user.lastName || ""}` : ''}
                          </td>
                      )
                    }
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default Events
