import { authHeader } from '../helpers';
const BASE_URL = "https://api.masonicsprig.com/";

export const memberService = {
    Members,
    addMember,
    MembersUpload,
    updateMember,
    deleteMember,
    Reports,
    BASE_URL
};

function Members() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/members?offset=0&limit=1000`, requestOptions).then(function (data) {
        return data.json();
    });
}

function Reports() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/reports?offset=0&limit=1000`, requestOptions).then(function (data) {
        return data.json();
    });
}

function addMember(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/members`, requestOptions).then(function (data) {
        return data.json();
    });
}

function MembersUpload(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/members/upload`, requestOptions).then(function (data) {
        return data
    });
}

function updateMember(formdata, memberId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/members/${memberId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function deleteMember(memberId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/members/${memberId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

