import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton
} from '@coreui/react'

import { mediaService, BASE_URL } from "../../../store/Services"
import UploadModel from "../../../common/Model/MediaUpload";
import ConfirmBox from "../../../common/Model/ConfirmBox"
import moment from 'moment'
import CIcon from '@coreui/icons-react';

const fields = [{ key: 'id', label: 'ID' }, 'image', 'title', 'type', 'mediaType', 'createAt_', 'action'];
const defaultMediaData = { title: '', image_: '', id: '', mediaType: '' };

const MediaList = () => {
  const [mediaList, setMedia] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [mediaTitle, setMediaTitle] = useState([]);
  const [mediaType, setMediaType] = useState([]);

  const [modal, setModal] = useState(false);
  const closeHandler = () => { setModal(false) }
  const openHandler = () => { setModal(true) }

  const [confirmModel, setConfirmModel] = useState({ modelState: false, data: {} });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await mediaService.Media();
    if(result.allMedia){

      result.allMedia = result.allMedia.map(media => {
        media.createAt_ = moment(media.createdAt).format('YYYY/MM/DD');
        return media;
      })
      setMedia(result.allMedia);
    }
  };

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const changeImageTitle = (event) => {
    setMediaTitle(event.target.value);
  };

  const changeMediaType = (event) => {
    setMediaType(event.target.value);
  };

  const uploadsubmitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let currentFile = selectedFiles[0];
    const formData = new FormData();
    formData.append('image', currentFile);
    formData.append('title', mediaTitle);
    formData.append('mediaType', mediaType);
    mediaService.MediaUpload(formData).then(async () => {
      await fetchData();
      setIsLoading(false);
      setModal(false);
    })
  }

  const deleteHandler = () => {
    setIsLoading(true);
    mediaService.deleteMedia(confirmModel.data.id).then(async () => {
      await fetchData();
      setIsLoading(false);
      setConfirmModel({ data: {}, modelState: false });
    }).catch(e => {
      setIsLoading(false);
    })
  }

  return (
    <>
      <CRow>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-between">
                <CCol xs="6" lg="6">Media</CCol>
                <CCol xs="6" lg="6">
                  <div className="text-right">
                    <CButton
                      color="primary"
                      onClick={openHandler}
                      className="px-4 ml-2" >
                      Upload
                    </CButton>
                  </div>
                  <ConfirmBox
                    closeHandler={() => { setConfirmModel({ data: {}, modelState: false }) }}
                    model={confirmModel.modelState}
                    submitAction={deleteHandler}
                    headerTitle={"Delete Media"}
                    message={"Are you sure to delete it?"}
                    isLoading={isLoading}
                  />
                  {
                    true && <UploadModel
                      closeHandler={closeHandler}
                      model={modal}
                      submitHandler={uploadsubmitHandler}
                      selectFile={selectFile}
                      changeTitle={changeImageTitle}
                      changeMediaType={changeMediaType}
                      isLoading={isLoading}
                    />
                  }
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={mediaList.map(media => {
                  for (let key in media) {
                    media[key] = media[key] || "";
                  }
                  return media;
                })}
                fields={fields}
                striped
                itemsPerPage={20}
                pagination
                sorter
                tableFilter
                scopedSlots={{
                  'action':
                    (item) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="danger"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setConfirmModel({ modelState: true, data: item })
                            }}
                          >
                            Delete
                            </CButton>
                        </td>
                      )
                    },
                    'type':
                    (item) => {
                      return (
                        <td className="py-2" style={{textTransform: "uppercase"}}>
                          {(item.image || "").toLowerCase().split(".").pop()}
                        </td>
                      )
                    },
                    'mediaType':
                    (item) => {
                      return (
                        <td className="py-2" style={{textTransform: "capitalize"}}>
                          {item.mediaType}
                        </td>
                      )
                    },
                  'image': (item)=>{

                    if(item.type === "video"){
                      return (
                        <td>
                          <a href={BASE_URL+item.image} target="_blank">
                            <img src={BASE_URL+'public/media/video.png'} style={{width: '80px', height: '80px'}}/>
                          </a>
                        </td>
                      )
                    }else{
                      return (
                        <td>
                          <a href={BASE_URL+item.image} target="_blank">
                            <img src={BASE_URL+item.image} style={{width: '80px', height: '80px'}}/>
                          </a>
                        </td>
                      )
                    }
                  }
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default MediaList
