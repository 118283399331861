import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton
} from '@coreui/react'

import { grandLodgeService } from "../../../store/Services"
import GrandLodgeModal from "../../../common/Model/LodgeModal"
import ConfirmBox from "../../../common/Model/ConfirmBox"
import { Link } from 'react-router-dom';
import CIcon from '@coreui/icons-react';

const fields = [{ key: 'id', label: 'ID' }, 'state', 'type', 'name', 'phone', 'website', 'action'];
const defaultGrandLodgeData = { id: '', name: '', address: '', city: '', state: '', region: '', zipcode: '', phone: '', website: '', message: '', meetingSchedule: '', messageTitle: '' };

const GrandLodge = () => {

  const [grandLodges, setGrandLodges] = useState([]);



  const [grandLoadgeModal, setModal] = useState(false);
  const [confirmModel, setConfirmModel] = useState({ modelState: false, data: {} });
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [grandLodgeData, setGrandLodge] = useState(defaultGrandLodgeData);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await grandLodgeService.getGrandLodge();
    setGrandLodges(result.grandLodges);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { id, name, address, city, state, region, zipcode, phone, website, message, meetingSchedule, messageTitle } = grandLodgeData;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('zipcode', zipcode);
    formData.append('region', region);
    formData.append('state', state);
    formData.append('phone', phone);
    formData.append('message', message);
    formData.append('website', website);
    formData.append('meetingSchedule', meetingSchedule);
    formData.append('messageTitle', messageTitle);

    if (id) {
      grandLodgeService.updateGrandLodge(formData, id).then(async () => {
        await fetchData();
        setIsLoading(false);
        setModal(false);
      }).catch(e => {
        setIsLoading(false);
      })
    } else {
      grandLodgeService.addGrandLodge(formData).then(async () => {
        await fetchData();
        setIsLoading(false);
        setModal(false);
      }).catch(e => {
        setIsLoading(false);
      })
    }
  }

  const deleteHandler = () => {
    setIsLoading(true);
    grandLodgeService.deleteGrandLodge(confirmModel.data.id).then(async () => {
      await fetchData();
      setIsLoading(false);
      setConfirmModel(false);
    }).catch(e => {
      setIsLoading(false);
    })
  }

  const grandLodgeModalHandler = (item) => {
    if (item) {
      setGrandLodge(item);
      setIsUpdate(true);
    } else {
      setGrandLodge(defaultGrandLodgeData)
      setIsUpdate(false);
    }
    setModal(!grandLoadgeModal)
  }

  const onChange = ({ target: { name, value } }) => {
    setGrandLodge({
      ...grandLodgeData, [name]: value
    })
  }

  return (
    <>
      <CRow>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-between">
                <CCol xs="6" lg="6">Grand Lodges</CCol>
                <CCol xs="6" lg="6">
                  <div className="text-right">
                    <CButton
                      color="primary"
                      onClick={() => grandLodgeModalHandler()}
                      className="px-4" >
                      Add
                    </CButton>
                  </div>
                  {grandLoadgeModal && <GrandLodgeModal
                    closeHandler={() => {
                      setModal(false);
                    }}
                    model={grandLoadgeModal}
                    isUpdate={isUpdate}
                    submitHandler={submitHandler}
                    onChange={onChange}
                    lodgeData={grandLodgeData}
                    isLoading={isLoading}
                  />}
                  <ConfirmBox
                    closeHandler={() => { setConfirmModel({ data: {}, modelState: false }) }}
                    model={confirmModel.modelState}
                    submitAction={deleteHandler}
                    headerTitle={"Delete Grand Lodge"}
                    message={"Are you sure to delete it?"}
                    isLoading={isLoading}
                  />
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={grandLodges.map(member => {
                  for (let key in member) {
                    member[key] = member[key] || "";
                  }
                  return member;
                })}
                fields={fields}
                striped
                itemsPerPage={20}
                pagination
                sorter
                tableFilter
                scopedSlots={{
                  'action':
                    (item) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              grandLodgeModalHandler(item)
                            }}
                            className="mr-2"
                          >
                            Edit
                          </CButton>
                          <CButton
                            color="danger"
                            variant="outline"
                            size="sm"
                            className="mr-2"
                            onClick={() => {
                              setConfirmModel({ modelState: true, data: item })
                            }}
                          >
                            Delete
                          </CButton>

                          <Link to={'/group-detail/' + item.id}>
                            <CButton
                              color="success"
                              variant="outline"
                              size="sm"
                              className="mr-2"
                            >
                              View
                            </CButton>
                          </Link>
                        </td>
                      )
                    },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default GrandLodge
