import { authHeader } from '../helpers';
export const BASE_URL = "https://api.masonicsprig.com/";
export const mediaService = {
    Media,
    MediaUpload,
    deleteMedia
};

function Media() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/media?offset=0&limit=1000`, requestOptions).then(function (data) {
        return data.json();
    });
}

function MediaUpload(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/media`, requestOptions).then(function (data) {
        return data
    });
}

function deleteMedia(memberId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/media/${memberId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

