import React from 'react'
import {
    CModalFooter,
    CModalBody,
    CModalHeader,
    CModal,
    CButton,
    CSpinner
} from '@coreui/react'


const ConfirmBox = (props) => {
    const { closeHandler, model, headerTitle, message, submitAction, isLoading, cancelLabel = 'CANCEL' } = props;
    return (
        <>
            <CModal
                show={model}
                onClose={closeHandler}
            >
                <CModalHeader closeButton> {headerTitle} </CModalHeader>
                <CModalBody>
                    <p>{message}</p>
                </CModalBody>
                <CModalFooter>
                    {
                        submitAction ? 
                            <CButton color="primary" onClick={() => submitAction()} disabled={isLoading}>OK {isLoading ? <CSpinner color="info" size="sm" /> : ""}</CButton>
                        : ''
                    }{' '}
                    <CButton color="danger" onClick={closeHandler} disabled={isLoading}>{cancelLabel}</CButton>{' '}
                </CModalFooter>
            </CModal>
        </>
    )
}

export default ConfirmBox;
