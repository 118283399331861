import React, { useEffect, useState } from 'react'
import {
    CCard,
    CCol,
    CRow,
    CSpinner,
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CLabel,
    CInputGroup,
    CInput,
    CImg,
    CTextarea,
    CButton
} from '@coreui/react'

import { groupService, notificationService } from "../../../store/Services"
import moment from 'moment'
import { useParams } from 'react-router-dom'
import Select from 'react-select';
import CIcon from '@coreui/icons-react'

const GroupDetail = () => {

    const [group, setGroup] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isGroupLeadershipSubmitting, setIsGroupLeadershipSubmitting] = useState(false);
    const [usersList, setUsersList] = useState([]);

    const grandLodgeLeaderOptions = ['Grand Master', 'Junior Grand Steward', 'Deputy Grand Master', 'Grand LEO', 'Senior Grand Warden', 'Grand Marshal', 'Junior Grand Warden', 'Grand Chaplain', 'Grand Secretary', 'Grand Orator', 'Grand Treasurer', 'Grand Standard Bearer', 'Senior Grand Deacon', 'Grand Pursuivant', 'Junior Grand Deacon', 'Grand Tyler', 'Senior Grand Steward', 'Grand Piper'].map(item => ({ label: item, value: item }));

    const localLodgeLeaderOptions = ['Worshipful Master', 'Senior Steward', 'Senior Warden', 'Junior Steward', 'Junior Warden', 'LEO', 'Secretary', 'Marshal', 'Treasurer', 'Chaplain', 'Senior Deacon', 'Tiler', 'Junior Deacon'].map(item => ({ label: item, value: item }));

    let { groupId } = useParams();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const result = await groupService.groupDetail(groupId);
        const usersData = await notificationService.getActiveUsers();
        if (usersData.users) {
            let users = usersData.users.map(user => ({ label: (user.firstName + " " + user.lastName + " " + `(${user.email})`), value: user.userId }));
            setUsersList(users);
        }
        if (result.group) {
            if (!result.group.leaderships.length) {
                result.group.leaderships.push({ userId: null, positionName: null });
            }
            result.group.leadershipsSelections = result.group.type == 'G' ? grandLodgeLeaderOptions : localLodgeLeaderOptions;
            setGroup(result.group);
        }
        setIsLoading(false);
    };

    const submitGroupLeadership = async () => {
        setIsGroupLeadershipSubmitting(true);
        const leaderships = [];

        group.leaderships.forEach(item => {
            if (item.userId && item.positionName) {
                leaderships.push({
                    userId: item.userId,
                    positionName: item.positionName
                })
            }
        })
        await groupService.submitLeadership(groupId, {
            leaderships
        });

        fetchData().then(() => {
            setIsGroupLeadershipSubmitting(false);
        })
    }

    const removeLeadershipRow = (index) => {
        if (group.leaderships.length > 1) {
            group.leaderships.splice(index, 1);
            setGroup({ ...group });
        }
    }

    const addLeadershipRow = (index) => {
        group.leaderships.push({ userId: null, positionName: null });
        setGroup({ ...group });
    }

    if (isLoading) {
        return (
            <CRow>
                <CCol xs="12" lg="12">
                    <CCard>
                        <div style={{ textAlign: 'center', padding: '50px' }}>
                            <CSpinner
                                color="primary"
                                style={{ width: '4rem', height: '4rem' }}
                            />
                        </div>
                    </CCard>
                </CCol>
            </CRow>
        );
    }

    return (
        <CRow>
            <CCol xs="12" lg="12">
                <CCard style={{ padding: '50px' }}>
                    <CTabs activeTab="info">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="info">
                                    Group Info
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="group-admin">
                                    Group Admins
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="leadership" hidden={group.type == 'GR'}>
                                    Leadership
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>
                            <CTabPane data-tab="info">
                                <CRow style={{ padding: 20 }}>
                                    <CCol md="12">
                                        <CRow>
                                            <CCol md="4">
                                                <CLabel><b>Name</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={group.name}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                            {
                                                group.type === 'L' || group.type === 'GR' ?
                                                    <CCol md="4">
                                                        <CLabel><b>Parent Lodge</b></CLabel>
                                                        <CInputGroup className="mb-3">
                                                            <CInput
                                                                style={{ background: '#fff' }}
                                                                value={group.parent ? group.parent.name : ""}
                                                                readOnly
                                                            />
                                                        </CInputGroup>
                                                    </CCol> : ""
                                            }
                                            <CCol md={group.type === 'L' || group.type === 'GR' ? '4' : '8'}>
                                                <CLabel><b>Address</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={group.address}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol md="4">
                                                <CLabel><b>City</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={group.city}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                            <CCol md="4">
                                                <CLabel><b>State</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={group.state}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                            <CCol md="4">
                                                <CLabel><b>Region</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={group.region}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol md="4">
                                                <CLabel><b>Zip Code</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={group.zipcode}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                            <CCol md="4">
                                                <CLabel><b>Phone</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={group.phone}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                            <CCol md="4">
                                                <CLabel><b>Website</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={group.website}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol md="12">
                                                <CLabel><b>Meeting Schedule</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CTextarea
                                                        style={{ background: '#fff' }}
                                                        value={group.meetingSchedule}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol md="12">
                                                <CLabel><b>Message Title</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={group.messageTitle}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol md="12">
                                                <CLabel><b>Message</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CTextarea
                                                        style={{ background: '#fff' }}
                                                        value={group.message}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                        </CRow>

                                        <CRow>
                                            <CCol md="12">
                                                <CLabel><b>Contact Persons</b></CLabel>
                                                {
                                                    group.contactPersons.map(user => {
                                                        if (!user.user) return (<></>)
                                                        user = user.user;
                                                        return (
                                                            <CInputGroup className="mb-3">
                                                                <CRow>
                                                                    <CCol md="4">
                                                                        <CInput
                                                                            style={{ background: '#fff' }}
                                                                            value={
                                                                                [
                                                                                    user.firstName,
                                                                                    user.lastName
                                                                                ].join(" ")
                                                                            }
                                                                            readOnly
                                                                        />
                                                                    </CCol>
                                                                    <CCol md="4">
                                                                        <CInput
                                                                            style={{ background: '#fff' }}
                                                                            value={user.email}
                                                                            readOnly
                                                                        />
                                                                    </CCol>
                                                                    <CCol md="4">
                                                                        <CInput
                                                                            style={{ background: '#fff' }}
                                                                            value={user.contactNumber}
                                                                            readOnly
                                                                        />
                                                                    </CCol>
                                                                </CRow>
                                                            </CInputGroup>
                                                        )
                                                    })
                                                }
                                            </CCol>
                                        </CRow>

                                    </CCol>
                                </CRow>
                            </CTabPane>
                            <CTabPane data-tab="group-admin">
                                <CRow style={{ padding: 20 }}>
                                    {
                                        group.groupAdmins ? group.groupAdmins.map(item => {
                                            const user = item.user;
                                            if (!user) return (<CCol md="4"></CCol>);
                                            return (
                                                <CCol md="4">
                                                    <div style={{ padding: 10, border: '1px solid #ddd', margin: 10 }}>
                                                        <CImg src={`${groupService.BASE_URL}${user.profilePicture ? user.profilePicture : 'public/default-placeholder-image.png'}`} style={{ maxWidth: '100%' }} style={{ height: 300, width: '100%' }} />
                                                        <h4 style={{ marginTop: 10, textAlign: 'center', display: 'block' }}>{user.firstName + " " + user.lastName}</h4>
                                                        <p style={{ marginTop: 10, textAlign: 'center', display: 'block' }}>{user.email}</p>
                                                    </div>
                                                </CCol>
                                            )
                                        }) : ''
                                    }
                                </CRow>
                            </CTabPane>
                            <CTabPane data-tab="leadership">
                                <div style={{ padding: 20 }}>
                                    {
                                        group.leaderships.map((item, i) => {

                                            const allSelectedUsers = [];
                                            const allSelectedPositions = [];
                                            group.leaderships.forEach((d, j) => {
                                                if (i != j) {
                                                    allSelectedUsers.push(d.userId);
                                                    allSelectedPositions.push(d.positionName);
                                                }
                                            });
                                            const optedUserLists = usersList.filter(d => !allSelectedUsers.includes(d.value));
                                            const optedPositions = group.leadershipsSelections.filter(d => !allSelectedPositions.includes(d.value));
                                            return (
                                                <CRow>
                                                    <CCol md="5">
                                                        <CLabel htmlFor="leaderUser">User</CLabel>
                                                        <CInputGroup className="mb-3">
                                                            <Select
                                                                style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }}
                                                                name="leaderUser"
                                                                id="leaderUser"
                                                                className="select-box"
                                                                required
                                                                isClearable={true}
                                                                onChange={(e_) => {
                                                                    group.leaderships[i].userId = e_ ? e_.value : null;
                                                                    setGroup({ ...group });
                                                                }}
                                                                value={usersList.find(user => user.value == item.userId)}
                                                                options={optedUserLists}
                                                            //value={ userList.filter(item => eventData.leaderUser.includes(item.value)) }
                                                            />
                                                        </CInputGroup>
                                                    </CCol>
                                                    <CCol md="5">
                                                        <CLabel htmlFor="position">Position</CLabel>
                                                        <CInputGroup className="mb-3">
                                                            <Select
                                                                style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }}
                                                                name="position"
                                                                id="position"
                                                                className="select-box"
                                                                required
                                                                isClearable={true}
                                                                onChange={(e_) => {
                                                                    group.leaderships[i].positionName = e_ ? e_.value : null;
                                                                    setGroup({ ...group });
                                                                }}
                                                                options={optedPositions}
                                                                value={group.leadershipsSelections.find(position => position.value == item.positionName)}
                                                            />
                                                        </CInputGroup>
                                                    </CCol>
                                                    <CCol md="2">
                                                        <CLabel htmlFor="position">&nbsp;</CLabel>
                                                        <CInputGroup className="mb-3">
                                                            <CButton
                                                                color="primary"
                                                                variant="outline"
                                                                onClick={() => {
                                                                    addLeadershipRow(i);
                                                                }}
                                                                className="mr-2"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="c-icon">
                                                                    <polygon fill="var(--ci-primary-color, currentColor)" points="440 240 272 240 272 72 240 72 240 240 72 240 72 272 240 272 240 440 272 440 272 272 440 272 440 240" class="ci-primary" />
                                                                </svg>

                                                            </CButton>

                                                            <CButton
                                                                color="danger"
                                                                variant="outline"
                                                                onClick={() => {
                                                                    removeLeadershipRow(i);
                                                                }}
                                                                className="mr-2"
                                                            >
                                                                <CIcon name={'cilTrash'} />
                                                            </CButton>
                                                        </CInputGroup>
                                                    </CCol>
                                                </CRow>
                                            )
                                        })
                                    }
                                    <div style={{ textAlign: "right" }}>
                                        <CButton onClick={() => { submitGroupLeadership() }} color="primary" type="button" disabled={isGroupLeadershipSubmitting}> Submit {isGroupLeadershipSubmitting ? <CSpinner color="info" size="sm" /> : ""}</CButton>
                                    </div>
                                </div>
                            </CTabPane>
                        </CTabContent>
                    </CTabs>
                </CCard>
            </CCol>
        </CRow>
    )
}

export default GroupDetail;
