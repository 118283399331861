import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from './store/helpers';
import { alertActions } from './store/actions';
import {PrivateRoute} from "./store/components";
import {Login} from "./views/pages/login/Login";
import Register from "./views/pages/register/Register";
import Page404 from "./views/pages/page404/Page404"
import Page500 from "./views/pages/page500/Page500"
import TheLayout from "./containers/TheLayout"
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
        // clear alert on location change
        dispatch(alertActions.clear());
    });
}
  render() {
    return (
      <Router history={history}>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route path="/register" name="Register Page" render={props => <Register {...props}/>}  />
              <Route path="/404" name="Page 404" render={props => <Page404 {...props}/>}   />
              <Route path="/500" name="Page 500" render={props => <Page500 {...props}/>}  />
              <PrivateRoute path="/" name="Home" component={TheLayout} />
            </Switch>
          </React.Suspense>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
      alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 
