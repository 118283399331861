import React, { useEffect, useState } from 'react'
import {
    CCard,
    CCol,
    CRow,
    CSpinner,
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CLabel,
    CInputGroup,
    CInput,
    CImg,
    CButton,
    CPagination,
} from '@coreui/react'

import { userService, localLodgeService, grandLodgeService, groupsService } from "../../../store/Services"
import moment from 'moment'
import CIcon from '@coreui/icons-react';
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'

const UserDetail = () => {

    const [user, setUser] = useState([]);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [calendarEventsCount, setCalendarEventsCount] = useState(0);
    const [calendarEventsCurrentPage, setCalendarEventsCurrentPage] = useState(1);
    const itemsPerPage = 12;

    const [localLodges, setLocalLodges] = useState([]);
    const [grandLodges, setGrandLodges] = useState([]);
    const [groups, setGroups] = useState([]);
    const [localLodgesAdmin, setLocalLodgesAdmin] = useState([]);
    const [grandLodgesAdmin, setGrandLodgesAdmin] = useState([]);
    const [groupsAdmin, setGroupsAdmin] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isGroupAdminSubmitting, setIsGroupAdminSubmitting] = useState(false);

    let { userId } = useParams();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const result = await userService.userDetail(userId);
        const localLodge = await localLodgeService.getLocalLodge();
        const grandLodge = await grandLodgeService.getGrandLodge();
        const groups = await groupsService.getGroups();
        if (result.user) {
            setUser(result.user);
            setLocalLodges(localLodge.localLodges);
            setGrandLodges(grandLodge.grandLodges);
            setGroups(groups.groups);

            setLocalLodgesAdmin(result.user.groupsAdminsList.map(item => item.groupId).filter(item => localLodge.localLodges.findIndex(item1 => item1.id === item) > -1));
            setGrandLodgesAdmin(result.user.groupsAdminsList.map(item => item.groupId).filter(item => grandLodge.grandLodges.findIndex(item1 => item1.id === item) > -1));
            setGroupsAdmin(result.user.groupsAdminsList.map(item => item.groupId).filter(item => groups.groups.findIndex(item1 => item1.id === item) > -1));

        }
        setIsLoading(false);
    };

    const fetchCalendarEvents = async (offset, limit) => {
        const result = await userService.userCalendarEvents(userId, offset, limit);
        const eventCountsResult = await userService.userCalendarEventsCount(userId);
        setCalendarEvents(result.calendarEvents)
        setCalendarEventsCount(eventCountsResult.count)
    }

    useEffect(() => {
        fetchCalendarEvents((calendarEventsCurrentPage - 1) * itemsPerPage, itemsPerPage);
    }, [calendarEventsCurrentPage]);

    const totalEventsPages = Math.ceil(calendarEventsCount / itemsPerPage);
    const handleEventPageChange = (pageNumber) => {
        setCalendarEvents([]);
        setCalendarEventsCurrentPage(pageNumber);
    };

    const submitGroupAdmin = async () => {
        setIsGroupAdminSubmitting(true);
        let allGroupsAdmin = [];
        allGroupsAdmin = allGroupsAdmin.concat(localLodgesAdmin, grandLodgesAdmin, groupsAdmin);
        await userService.submitGroupAdmin({ userId, groupsAdmin: allGroupsAdmin });
        setIsGroupAdminSubmitting(false);
    }

    if (isLoading) {
        return (
            <CRow>
                <CCol xs="12" lg="12">
                    <CCard>
                        <div style={{ textAlign: 'center', padding: '50px' }}>
                            <CSpinner
                                color="primary"
                                style={{ width: '4rem', height: '4rem' }}
                            />
                        </div>
                    </CCard>
                </CCol>
            </CRow>
        );
    }

    return (
        <CRow>
            <CCol xs="12" lg="12">
                <CCard style={{ padding: '50px' }}>
                    <CTabs activeTab="info">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="info">
                                    User Info
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="gallery">
                                    Gallery
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="calendar">
                                    Public Calendar
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="group-admin">
                                    Group Admin
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>
                            <CTabPane data-tab="info">
                                <CRow style={{ padding: 20 }}>
                                    <CCol md="4">
                                        <CImg src={`${userService.BASE_URL}${user.profilePicture ? user.profilePicture : 'public/default-placeholder-image.png'}`} style={{ maxWidth: '100%' }} />
                                    </CCol>
                                    <CCol md="8">
                                        <CRow>
                                            <CCol md="6">
                                                <CLabel><b>Email</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={user.email}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                            <CCol md="6">
                                                <CLabel><b>Phone</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={user.contactNumber}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol md="6">
                                                <CLabel><b>Grand Lodge</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={user.grandLodge ? user.grandLodge.name : ''}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                            <CCol md="6">
                                                <CLabel><b>Local Lodge</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInput
                                                        style={{ background: '#fff' }}
                                                        value={user.localLodge ? user.localLodge.name : ''}
                                                        readOnly
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </CTabPane>
                            <CTabPane data-tab="gallery">
                                <CRow style={{ padding: 20 }}>
                                    {
                                        user.gallery ? user.gallery.map(gallery => {
                                            return (
                                                <CCol md="4" style={{ padding: 10 }}>
                                                    <CImg src={`${userService.BASE_URL}${gallery.image}`} style={{ maxWidth: '100%' }} style={{ height: 300, maxWidth: '100%' }} />
                                                </CCol>
                                            )
                                        }) : ''
                                    }
                                </CRow>
                            </CTabPane>
                            <CTabPane data-tab="calendar">
                                <CRow style={{ padding: 20, minHeight: 100 }}>
                                    {
                                        (calendarEvents?.length > 0) ? calendarEvents.map(event => {
                                            return (
                                                <CCol md="4">
                                                    <div style={{ padding: 10, border: '1px solid #ddd', margin: 10 }}>
                                                        <CImg src={`${userService.BASE_URL}${event.event.eventStockImage ? event.event.eventStockImage : 'public/default-placeholder-image.png'}`} style={{ maxWidth: '100%' }} style={{ height: 300, width: '100%' }} />
                                                        <h4 style={{ marginTop: 10, textAlign: 'center', display: 'block' }}>{event.event.eventTitle}</h4>
                                                        <p style={{ marginTop: 10, textAlign: 'center', display: 'block' }}>{event.event.eventDescription}</p>
                                                        <p style={{ textAlign: 'center', margin: 2 }}><small><b>Date:</b> {moment(event.event.eventStartTime).format('LLLL')}</small></p>
                                                        <p style={{ textAlign: 'center', margin: 2 }}><small><b>Place:</b> {event.event.placeName}</small></p>
                                                    </div>
                                                </CCol>
                                            )
                                        }) : ''
                                    }


                                </CRow>
                                <CRow style={{ padding: 20 }}>
                                    {
                                        (calendarEventsCount > 0) ?
                                            <CPagination
                                                activePage={calendarEventsCurrentPage}
                                                pages={totalEventsPages}
                                                onActivePageChange={(i) => handleEventPageChange(i)}
                                            ></CPagination>
                                            : ''
                                    }
                                </CRow>
                            </CTabPane>
                            <CTabPane data-tab="group-admin">
                                <CRow style={{ padding: 20 }}>
                                    <CCol md="12">
                                        <CRow>
                                            <CCol md="4">
                                                <CLabel><b>Local Lodge Admin</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <Select
                                                        style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }}
                                                        name="localLodgeAdmin"
                                                        id="localLodgeAdmin"
                                                        required
                                                        value={localLodges.filter(item => localLodgesAdmin.includes(item.id)).map(item => ({ label: item.name, value: item.id }))}
                                                        onChange={(e_) => {
                                                            setLocalLodgesAdmin(e_.map(item => item.value));
                                                            // combineSelectedGroupsAdmin(); 
                                                        }}
                                                        isMulti={true}
                                                        options={localLodges.map(item => ({ label: item.name, value: item.id }))}
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                            <CCol md="4">
                                                <CLabel><b>Grand Lodge Admin</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <Select
                                                        style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }}
                                                        name="grandLodgeAdmin"
                                                        id="grandLodgeAdmin"
                                                        value={grandLodges.filter(item => grandLodgesAdmin.includes(item.id)).map(item => ({ label: item.name, value: item.id }))}
                                                        required
                                                        onChange={(e_) => {
                                                            setGrandLodgesAdmin(e_.map(item => item.value));
                                                        }}
                                                        isMulti={true}
                                                        options={grandLodges.map(item => ({ label: item.name, value: item.id }))}
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                            <CCol md="4">
                                                <CLabel><b>Group Admin</b></CLabel>
                                                <CInputGroup className="mb-3">
                                                    <Select
                                                        style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }}
                                                        name="groupsAdmin"
                                                        id="groupsAdmin"
                                                        className="select-box"
                                                        value={groups.filter(item => groupsAdmin.includes(item.id)).map(item => ({ label: item.name, value: item.id }))}
                                                        required
                                                        onChange={(e_) => {
                                                            setGroupsAdmin(e_.map(item => item.value));
                                                        }}
                                                        isMulti={true}
                                                        options={groups.map(item => ({ label: item.name, value: item.id }))}
                                                    />
                                                </CInputGroup>
                                            </CCol>
                                        </CRow>
                                        <div style={{ textAlign: "right" }}>
                                            <CButton onClick={() => { submitGroupAdmin() }} color="primary" type="button" disabled={isGroupAdminSubmitting}> Submit {isGroupAdminSubmitting ? <CSpinner color="info" size="sm" /> : ""}</CButton>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CTabPane>
                        </CTabContent>
                    </CTabs>
                </CCard>
            </CCol>
        </CRow>
    )
}

export default UserDetail;
