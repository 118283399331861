import { authHeader } from '../helpers';
const BASE_URL = "https://api.masonicsprig.com/";

export const notificationService = {
    notificationsList,
    sendNotification,
    getActiveUsers
};

function notificationsList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/get-admin-notifications?offset=0&limit=100000`, requestOptions).then(function (data) {
        return data.json();
    });
}

function getActiveUsers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/get-active-users`, requestOptions).then(function (data) {
        return data.json();
    });
}

function sendNotification(formdata) {
    const authHeader_ = authHeader();
    authHeader_['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: authHeader_,
        body: JSON.stringify(formdata)
    };

    return fetch(`${BASE_URL}api/admin/send-notifications`, requestOptions).then(function (data) {
        return data.json();
    });
}