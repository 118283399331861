import { authHeader } from '../helpers';
export const BASE_URL = "https://api.masonicsprig.com/";

function listResource() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/resources?offset=0&limit=1000`, requestOptions).then(function (data) {
        return data.json();
    });
}

function createResource(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/resources`, requestOptions).then(function (data) {
        return data
    });
}

function deleteResource(resourceId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/resources/${resourceId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function updateResource(resourceId, formData) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: formData
    };

    return fetch(`${BASE_URL}api/admin/resources/${resourceId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function listResourceCategories() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/resource-categories?offset=0&limit=1000`, requestOptions).then(function (data) {
        return data.json();
    });
}

function createResourceCategory(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/resource-categories`, requestOptions).then(function (data) {
        return data
    });
}

function deleteResourceCategory(categoryId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/resource-categories/${categoryId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function updateResourceCategory(categoryId, formData) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: formData
    };

    return fetch(`${BASE_URL}api/admin/resource-categories/${categoryId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

export const resourcesService = {
    listResource,
    createResource,
    deleteResource,
    updateResource,
    listResourceCategories,
    createResourceCategory,
    deleteResourceCategory,
    updateResourceCategory,
    BASE_URL
};