import React from 'react'
import {
    CModalFooter,
    CModalBody,
    CModalHeader,
    CModal,
    CButton,
    CForm,
    CInput,
    CInputGroup,
    CSpinner,
    CLabel,
    CRow,
    CCol
} from '@coreui/react';


const MessageModal = (props) => {
    const { closeHandler, model, submitHandler, onChange, messageData, isLoading, isUpdate } = props;
    return (
        <>
            <CModal
                size="lg"
                show={model}
                onClose={closeHandler}
                closeOnBackdrop={!isLoading}
            >
                <CForm onSubmit={submitHandler}>
                    <CModalHeader closeButton>{ isUpdate ? 'Update' : 'Add'} Message</CModalHeader>
                    <CModalBody>
                        <CRow>
                            <CCol md="6">
                                <CLabel htmlFor="message">Message</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Message"
                                        onChange={onChange}
                                        value={messageData.message}
                                        name="message"
                                        id="message"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="messageType">Message Type</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Message Type"
                                        onChange={onChange}
                                        value={messageData.messageType}
                                        name="messageType"
                                        id="messageType"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="primary" type="submit" disabled={isLoading}> { isUpdate ? 'Update' : 'Add'} {isLoading ? <CSpinner color="info" size="sm" /> : ""}</CButton>
                    </CModalFooter>
                </CForm>
            </CModal>
        </>
    )
}

export default MessageModal;
