import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CImg,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CLabel,
    CRow,
    CSpinner,
    CTextarea
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import { userService } from 'src/store/Services';

const Profile = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUser] = useState({ userId: null, firstName: '', lastName: '', bio: '', contactNumber: '', email: '', password: '' });
    const [profileImage, _setSelectProfileImage] = useState(null);

    const selectProfileImage = (event) => {
        _setSelectProfileImage(event.target.files?.[0] ?? null);
    };

    useEffect(() => {
        fetchProfile()
    }, [])

    const fetchProfile = () => {
        try {
            const user_ = JSON.parse(localStorage.getItem('user'));
            return userService.getProfile(user_?.user?.userId).then(data => {
                setUser(data?.user);
            })
        } catch (e) { }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const { userId, firstName, lastName, bio, contactNumber, email, password } = userData;
        if (!userId) return;
        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('bio', bio);
        formData.append('contactNumber', contactNumber);
        formData.append('email', email);
        if (password) formData.append('password', password);
        if (profileImage) formData.append('profileImage', profileImage);

        userService.updateProfile(formData).then(async () => {
            setUser({ ...userData, password: '' })
            _setSelectProfileImage(null)
            await fetchProfile();
            setIsLoading(false);
        }).catch(e => {
            setIsLoading(false);
        })
    }

    const onChange = ({ target: { name, value } }) => {
        setUser({
            ...userData, [name]: value
        })
    }

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="6">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm onSubmit={handleSubmit}>
                                        <h2 style={{ textAlign: 'center' }}>Profile</h2>

                                        <CRow>
                                            <CCol md="6" style={{ margin: '0 auto', paddingBottom: '20px' }}>
                                                <div style={{ padding: 10, border: '1px solid #ddd' }}>
                                                    <CImg src={`${userService.BASE_URL}${userData?.profilePicture ? userData?.profilePicture : 'public/default-placeholder-image.png'}`} style={{ height: 100, width: '100%' }} />
                                                </div>
                                            </CCol>
                                        </CRow>

                                        <CLabel htmlFor="firstName">First Name *</CLabel>
                                        <CInputGroup className="mb-3">
                                            {/* <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend> */}
                                            <CInput
                                                required
                                                type="text"
                                                placeholder="First Name"
                                                onChange={onChange}
                                                value={userData.firstName}
                                                name="firstName" />
                                        </CInputGroup>
                                        <CLabel htmlFor="lastName">Last Name *</CLabel>
                                        <CInputGroup className="mb-3">
                                            {/* <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend> */}
                                            <CInput
                                                required
                                                type="text"
                                                placeholder="Last Name"
                                                onChange={onChange}
                                                value={userData.lastName}
                                                name="lastName" />
                                        </CInputGroup>
                                        <CLabel htmlFor="email">Email *</CLabel>
                                        <CInputGroup className="mb-3">
                                            {/* <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend> */}
                                            <CInput
                                                required
                                                type="email"
                                                placeholder="Email"
                                                onChange={onChange}
                                                value={userData.email}
                                                name="email" />
                                        </CInputGroup>
                                        <CLabel htmlFor="contactNumber">Contact Number *</CLabel>
                                        <CInputGroup className="mb-3">
                                            {/* <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend> */}
                                            <CInput
                                                required
                                                type="text"
                                                placeholder="Contact Number"
                                                onChange={onChange}
                                                value={userData.contactNumber}
                                                name="contactNumber" />
                                        </CInputGroup>
                                        <CLabel htmlFor="bio">Bio</CLabel>
                                        <CInputGroup className="mb-3">
                                            {/* <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend> */}
                                            <CTextarea
                                                placeholder="Bio"
                                                onChange={onChange}
                                                value={userData.bio}
                                                name="bio"
                                                id="bio"
                                            />
                                        </CInputGroup>
                                        <CLabel htmlFor="profilePicture">Profile Picture</CLabel>
                                        <CInputGroup className="mb-3">
                                            {/* <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend> */}
                                            <CInput
                                                accept=".jpg,.png,.jpeg"
                                                type="file"
                                                placeholder="Profile Picture"
                                                onChange={selectProfileImage}
                                                name="image"
                                            />
                                        </CInputGroup>
                                        <CLabel htmlFor="password">Password</CLabel>
                                        <CInputGroup className="mb-3">
                                            {/* <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend> */}
                                            <CInput
                                                type="text"
                                                placeholder="Password"
                                                onChange={onChange}
                                                value={userData.password}
                                                name="password" />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="6">
                                                <CButton
                                                    color="primary"
                                                    className="px-4"
                                                    type="submit"
                                                    disabled={isLoading}
                                                >Update {isLoading ? <CSpinner color="info" size="sm" /> : ""} </CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedProfile = connect(mapStateToProps)(Profile);
export { connectedProfile as Profile };