import React from 'react'
import {
    CModalFooter,
    CModalBody,
    CModalHeader,
    CModal,
    CButton,
    CForm,
    CInput,
    CInputGroup,
    CSpinner,
    CLabel,
    CRow,
    CCol,
    CTextarea,
    CImg
} from '@coreui/react';
import { memberService } from 'src/store/Services';


const MemberModal = (props) => {
    const { closeHandler, model, submitHandler, onChange, memberData, isLoading, isUpdate, selectProfileImage } = props;
    return (
        <>
            <CModal
                size="lg"
                show={model}
                onClose={closeHandler}
                closeOnBackdrop={!isLoading}
            >
                <CForm onSubmit={submitHandler}>
                    <CModalHeader closeButton>{isUpdate ? 'Update' : 'Add'} User</CModalHeader>
                    <CModalBody>
                        <CRow>
                            <CCol md="6">
                                <CLabel htmlFor="firstName">First Name</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="First Name"
                                        onChange={onChange}
                                        value={memberData.firstName}
                                        name="firstName"
                                        id="firstName"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="lastName">Last Name</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Last Name"
                                        onChange={onChange}
                                        value={memberData.lastName}
                                        name="lastName"
                                        id="lastName"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="memberId">Member ID</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="MemberId"
                                        onChange={onChange}
                                        value={memberData.memberId}
                                        name="memberId"
                                        id="memberId"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            {/* <CCol md="6">
                                <CLabel htmlFor="lodge">Lodge</CLabel>
                                <CInputGroup className="mb-3">
                                <CInput
                                    type="text"
                                    placeholder="Lodge"
                                    onChange={onChange}
                                    value={memberData.lodge}
                                    name="lodge"
                                    id="lodge"
                                />
                                </CInputGroup>
                            </CCol> */}
                            <CCol md="6">
                                <CLabel htmlFor="localLodge">Local Lodge</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Local Lodge"
                                        onChange={onChange}
                                        value={memberData.localLodge}
                                        name="localLodge"
                                        id="localLodge"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="6">
                                <CLabel htmlFor="grandLodge">Grand Lodge</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Grand Lodge"
                                        onChange={onChange}
                                        value={memberData.grandLodge}
                                        name="grandLodge"
                                        id="grandLodge"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                        </CRow>

                        {
                            memberData.user?.userId &&
                            <>
                                <hr />
                                <CRow>
                                    <CCol md="6">
                                        <CLabel htmlFor="profilePicture">Profile Picture</CLabel>
                                        <CInputGroup className="mb-3">
                                            <CInput
                                                accept=".jpg,.png,.jpeg"
                                                type="file"
                                                placeholder="Profile Picture"
                                                onChange={selectProfileImage}
                                                name="image"
                                            />
                                        </CInputGroup>
                                    </CCol>
                                    {
                                        <CCol md="3">
                                            <div style={{ padding: 10, border: '1px solid #ddd' }}>
                                                <CImg src={`${memberService.BASE_URL}${memberData.user?.profilePicture ? memberData.user?.profilePicture : 'public/default-placeholder-image.png'}`} style={{ height: 100, width: '100%' }} />
                                            </div>
                                        </CCol>
                                    }
                                </CRow>
                                <CRow>
                                    <CCol md="12">
                                        <CLabel htmlFor="bio">Bio</CLabel>
                                        <CInputGroup className="mb-3">
                                            <CTextarea
                                                placeholder="Bio"
                                                onChange={onChange}
                                                name="bio"
                                                value={memberData.bio}
                                                id="bio"
                                            />
                                        </CInputGroup>
                                    </CCol>
                                </CRow>
                            </>
                        }

                    </CModalBody>
                    <CModalFooter>
                        <CButton color="primary" type="submit" disabled={isLoading}> {isUpdate ? 'Update' : 'Add'} {isLoading ? <CSpinner color="info" size="sm" /> : ""}</CButton>
                    </CModalFooter>
                </CForm>
            </CModal >
        </>
    )
}

export default MemberModal;
