import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton
} from '@coreui/react'

import { resourcesService, BASE_URL } from "../../../store/Services"
import ConfirmBox from "../../../common/Model/ConfirmBox"
import moment from 'moment'
import ResourceCategoryModal from 'src/common/Model/ResourceCategoryModal';

const fields = [{ key: 'id', label: 'ID' }, 'name', 'parent', 'createdAt_', 'action'];
const defaultResourceCategoryData = { name: '', parentId: 0, id: 0 };

const ResourceCategoryList = () => {
  const [resourceCategoryList, setResourceCategories] = useState([]);
  const [resourceCategory, setResourceCategory] = useState(defaultResourceCategoryData);

  const [resourceCategoryModal, setResourceCategoryModal] = useState(false);

  const [confirmModel, setConfirmModel] = useState({ modelState: false, data: {} });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await resourcesService.listResourceCategories();
    result.resourceCategories.forEach(resourceCategory => {
      resourceCategory.createdAt_ = moment(resourceCategory.createdAt).format('YYYY/MM/DD');
      resourceCategory.parent = resourceCategory.parent?.name ?? '-'
    })
    setResourceCategories(result.resourceCategories);
  }

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('name', resourceCategory.name);
    formData.append('parentId', resourceCategory.parentId);

    if (resourceCategory?.id) {
      resourcesService.updateResourceCategory(resourceCategory.id, formData).then(async () => {
        await fetchData();
        setIsLoading(false);
        setResourceCategoryModal(false);
      })
    } else {
      resourcesService.createResourceCategory(formData).then(async () => {
        await fetchData();
        setIsLoading(false);
        setResourceCategoryModal(false);
      })
    }

  }

  const onChange = ({ target: { name, value } }) => {
    setResourceCategory({
      ...resourceCategory, [name]: value
    })
  }

  const deleteHandler = () => {
    setIsLoading(true);
    resourcesService.deleteResourceCategory(confirmModel.data.id).then(async () => {
      await fetchData();
      setIsLoading(false);
      setConfirmModel({ data: {}, modelState: false });
    }).catch(e => {
      setIsLoading(false);
    })
  }

  return (
    <>
      <CRow>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-between">
                <CCol xs="6" lg="6">Resource Category</CCol>
                <CCol xs="6" lg="6">
                  <div className="text-right">
                    <CButton
                      color="primary"
                      onClick={() => {
                        setResourceCategory(defaultResourceCategoryData);
                        setResourceCategoryModal(true)
                      }}
                      className="px-4 ml-2" >
                      Add Resource Category
                    </CButton>
                  </div>
                  <ConfirmBox
                    closeHandler={() => { setConfirmModel({ data: {}, modelState: false }) }}
                    model={confirmModel.modelState}
                    submitAction={deleteHandler}
                    headerName={"Delete Resource Category"}
                    message={"Are you sure to delete it?"}
                    isLoading={isLoading}
                  />
                  {
                    resourceCategoryModal && <ResourceCategoryModal
                      closeHandler={() => setResourceCategoryModal(false)}
                      model={resourceCategoryModal}
                      submitHandler={submitHandler}
                      resourceCategory={resourceCategory}
                      isLoading={isLoading}
                      onChange={onChange}
                      restCategories={resourceCategoryList}
                    />
                  }
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={resourceCategoryList.map(resourceCategory => {
                  for (let key in resourceCategory) {
                    resourceCategory[key] = resourceCategory[key] || "";
                  }
                  return resourceCategory;
                })}
                fields={fields}
                striped
                itemsPerPage={20}
                pagination
                sorter
                tableFilter
                scopedSlots={{
                  'action':
                    (item) => {
                      return (

                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setResourceCategory({
                                id: item.id,
                                name: item.name,
                                parentId: item.parentId
                              })
                              setResourceCategoryModal(true)
                            }}
                            className="mr-2"
                          >
                            Edit
                          </CButton>
                          <CButton
                            color="danger"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setConfirmModel({ modelState: true, data: item })
                            }}
                          >
                            Delete
                          </CButton>
                        </td>
                      )
                    },
                  'name':
                    (item) => {
                      return (
                        <td className="py-2" style={{ textTransform: "capitalize" }}>
                          {item.name}
                        </td>
                      )
                    },
                  'parent':
                    (item) => {
                      return (
                        <td className="py-2" style={{ textTransform: "capitalize" }}>
                          {item.parent}
                        </td>
                      )
                    },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default ResourceCategoryList
