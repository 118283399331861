import { authHeader } from '../helpers';
const BASE_URL = "https://api.masonicsprig.com/";

export const groupsService = {
    getGroups,
    addGroups,
    updateGroups,
    deleteGroups
};

function getGroups() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/groups`, requestOptions).then(function (data) {
        return data.json();
    });
}

function addGroups(formdata) {
    const authHeader_ = authHeader();
    authHeader_['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: authHeader_,
        body: JSON.stringify(formdata)
    };

    return fetch(`${BASE_URL}api/admin/groups`, requestOptions).then(function (data) {
        return data.json();
    });
}

function updateGroups(formdata, localLodgeId) {
    const authHeader_ = authHeader();
    authHeader_['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'PUT',
        headers: authHeader_,
        body: JSON.stringify(formdata)
    };

    return fetch(`${BASE_URL}api/admin/groups/${localLodgeId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function deleteGroups(localLodgeId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/groups/${localLodgeId}`, requestOptions).then(function (data) {
        return data.json();
    });
} 