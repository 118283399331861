import React from 'react'
import {
  CModalFooter,
  CModalBody,
  CModalHeader,
  CModal,
  CButton,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CSpinner,
  CRow,
  CCol,
  CLabel,
  CForm
} from '@coreui/react'

import CIcon from '@coreui/icons-react';
import Select from 'react-select';


const ResourceCategoryModal = (props) => {
  const { closeHandler, model, submitHandler, resourceCategory, isLoading, onChange, restCategories } = props;
  const categoryOptions = [
    { label: 'No Parent', value: 0 },
    ...restCategories
      .filter(o => o.id !== resourceCategory?.id && !o.parentId)
      .map(category => ({ label: category.name, value: category.id }))
  ];

  return (
    <>
      <CModal
        show={model}
        onClose={closeHandler}
      >
        <CForm onSubmit={submitHandler}>
          <CModalHeader closeButton>Resource Category</CModalHeader>
          <CModalBody>
            <CRow>
              <CCol md="12">
                <CLabel htmlFor="name">Name</CLabel>
                <CInputGroup className="mb-3">
                  <CInput
                    type="text"
                    placeholder="Name"
                    onChange={onChange}
                    name="name"
                    id="name"
                    value={resourceCategory?.name ?? ''}
                    required
                  />
                </CInputGroup>
              </CCol>
              <CCol md="12">
                <CLabel htmlFor="parentId">Parent Category</CLabel>
                <CInputGroup className="mb-3" style={{ display: 'block' }}>
                  <Select
                    style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }}
                    name="parentId"
                    id="parentId"
                    required
                    onChange={(e_) => {
                      onChange({ target: { name: 'parentId', value: e_.value } })
                    }}
                    options={categoryOptions}
                    value={categoryOptions.find(o => o.value === resourceCategory?.parentId)}
                  />
                </CInputGroup>
              </CCol>
            </CRow>

          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type="submit" disabled={isLoading}> {resourceCategory?.id > 0 ? 'Update' : 'Add'} {isLoading ? <CSpinner color="info" size="sm" /> : ""}</CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  )
}

export default ResourceCategoryModal
