import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton
} from '@coreui/react'

import { resourcesService } from "../../../store/Services"
import ConfirmBox from "../../../common/Model/ConfirmBox"
import moment from 'moment'
import ResourceModal from 'src/common/Model/ResourceModal';

const fields = [{ key: 'id', label: 'ID' }, 'name', 'category', 'createAt_', 'action'];
const defaultResourceData = { name: '', url: '', categoryId: null, id: 0 };

const ResourceList = () => {
  const [resourceList, setResources] = useState([]);
  const [resourceCategories, setResourceCategories] = useState([]);
  const [resource, setResource] = useState(defaultResourceData);

  const [resourceModal, setResourceModal] = useState(false);

  const [confirmModel, setConfirmModal] = useState({ modelState: false, data: {} });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await resourcesService.listResource();
    result.resources.forEach(resource => {
      resource.createAt_ = moment(resource.createdAt).format('YYYY/MM/DD');
      resource.categoryName = resource.category?.name ?? '-';

      return resource;
    })
    setResources(result.resources);
  }

  const fetchCategories = async () => {
    const categoriesResult = await resourcesService.listResourceCategories();
    categoriesResult.resourceCategories.forEach(resourceCategory => {
      resourceCategory.createdAt_ = moment(resourceCategory.createdAt).format('YYYY/MM/DD');
      resourceCategory.parent = resourceCategory.parent?.name ?? '-'
    })
    setResourceCategories(categoriesResult.resourceCategories);
  }

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('name', resource.name);
    formData.append('url', resource.url);
    formData.append('categoryId', resource.categoryId);

    if (!resource.categoryId) {
      alert("Please select category");
      return;
    }
    setIsLoading(true);
    if (resource?.id) {
      resourcesService.updateResource(resource.id, formData).then(async () => {
        await fetchData();
        setIsLoading(false);
        setResourceModal(false);
      })
    } else {
      resourcesService.createResource(formData).then(async () => {
        await fetchData();
        setIsLoading(false);
        setResourceModal(false);
      })
    }

  }

  const onChange = ({ target: { name, value } }) => {
    setResource({
      ...resource, [name]: value
    })
  }

  const deleteHandler = () => {
    setIsLoading(true);
    resourcesService.deleteResource(confirmModel.data.id).then(async () => {
      await fetchData();
      setIsLoading(false);
      setConfirmModal({ data: {}, modelState: false });
    }).catch(e => {
      setIsLoading(false);
    })
  }

  return (
    <>
      <CRow>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-between">
                <CCol xs="6" lg="6">Resources</CCol>
                <CCol xs="6" lg="6">
                  <div className="text-right">
                    <CButton
                      color="primary"
                      onClick={() => {
                        setResource(defaultResourceData);
                        setResourceModal(true)
                      }}
                      className="px-4 ml-2" >
                      Add Resource
                    </CButton>
                  </div>
                  <ConfirmBox
                    closeHandler={() => { setConfirmModal({ data: {}, modelState: false }) }}
                    model={confirmModel.modelState}
                    submitAction={deleteHandler}
                    headerName={"Delete Resource"}
                    message={"Are you sure to delete it?"}
                    isLoading={isLoading}
                  />
                  {
                    resourceModal && <ResourceModal
                      closeHandler={() => {
                        setResource(defaultResourceData);
                        setResourceModal(false)
                      }}
                      model={resourceModal}
                      submitHandler={submitHandler}
                      resource={resource}
                      isLoading={isLoading}
                      onChange={onChange}
                      resourceCategories={resourceCategories}
                    />
                  }
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={resourceList.map(resource => {
                  for (let key in resource) {
                    resource[key] = resource[key] || "";
                  }
                  return resource;
                })}
                fields={fields}
                striped
                itemsPerPage={20}
                pagination
                sorter
                tableFilter
                scopedSlots={{
                  'action':
                    (item) => {
                      return (

                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setResource(item)
                              setResourceModal(true)
                            }}
                            className="mr-2"
                          >
                            Edit
                          </CButton>
                          <CButton
                            color="danger"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setConfirmModal({ modelState: true, data: item })
                            }}
                          >
                            Delete
                          </CButton>
                        </td>
                      )
                    },
                  'name':
                    (item) => {
                      return (
                        <td className="py-2" style={{ textTransform: "capitalize" }}>
                          <a href={item.url} target="_blank">
                            {item.name}
                          </a>
                        </td>
                      )
                    },
                  'category':
                    (item) => {
                      return (
                        <td className="py-2" style={{ textTransform: "capitalize" }}>
                          {item.categoryName}
                        </td>
                      )
                    },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default ResourceList
