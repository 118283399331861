import { authHeader } from '../helpers';
const BASE_URL = "https://api.masonicsprig.com/";
export const groupService = {
    groupDetail,
    submitLeadership,
    BASE_URL
};

function groupDetail(groupId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${BASE_URL}api/admin/group-details/${groupId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function submitLeadership(groupId, formdata) {
    const authHeader_ = authHeader();
    authHeader_['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'PUT',
        headers: authHeader_,
        body: JSON.stringify(formdata)
    };
    return fetch(`${BASE_URL}api/admin/group-leaderships/${groupId}`, requestOptions).then(function (data) {
        return data.json();
    });
}