import { authHeader } from '../helpers';
const BASE_URL = "https://api.masonicsprig.com/";

export const eventsService = {
    getEvents,
    addEvents,
    updateEvents,
    deleteEvents,
    BASE_URL
};

function getEvents() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/events`, requestOptions).then(function (data) {
        return data.json();
    });
}

function addEvents(formdata) {
    const authHeader_ = authHeader();
    authHeader_['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: authHeader_,
        body: JSON.stringify(formdata)
    };

    return fetch(`${BASE_URL}api/admin/events`, requestOptions).then(function (data) {
        return data.json();
    });
}

function updateEvents(formdata, eventsId) {
    const authHeader_ = authHeader();
    authHeader_['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'PUT',
        headers: authHeader_,
        body: JSON.stringify(formdata)
    };

    return fetch(`${BASE_URL}api/admin/events/${eventsId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function deleteEvents(eventsId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/events/${eventsId}`, requestOptions).then(function (data) {
        return data.json();
    });
} 