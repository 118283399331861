import React from 'react'
import CIcon from '@coreui/icons-react'

const _nav = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Users',
    to: '/users',
    icon: 'cil-user'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Grand Lodge',
    to: '/grandlodge',
    icon: 'cil-puzzle'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Local Lodge',
    to: '/locallodge',
    icon: 'cil-puzzle'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Groups',
    to: '/groups',
    icon: 'cil-puzzle'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Media',
    to: '/media',
    icon: 'cil-puzzle'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Inbox',
    to: '/inbox',
    icon: 'cil-puzzle'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Events',
    to: '/events',
    icon: 'cil-puzzle'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Notifications',
    to: '/notifications',
    icon: 'cil-puzzle'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Not Registered',
    to: '/report',
    icon: 'cil-puzzle'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Resource Categories',
    to: '/resource-categories',
    icon: 'cil-puzzle'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Resources',
    to: '/resources',
    icon: 'cil-puzzle'
  }
]

export default _nav
