import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { userService } from 'src/store/Services';

const TheHeaderDropdown = () => {

  const [userData, setUser] = useState({});
  useEffect(() => {
    try {
      const user_ = JSON.parse(localStorage.getItem('user'));
      return userService.getProfile(user_?.user?.userId).then(data => {
        setUser(data?.user);
      })
    } catch (e) { }
  }, [])
  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          Admin
          {/* {[userData?.firstName, userData?.lastName].join(" ")} */}
          {/* <CImg
            src={'avatars/6.jpg'}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          /> */}
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Account</strong>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-user" className="mfe-2" />
          <Link to="/profile">Profile</Link>
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          <Link to="/login">Logout</Link>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
