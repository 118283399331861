import React from 'react'
import {
    CModalFooter,
    CModalBody,
    CModalHeader,
    CModal,
    CButton,
    CForm,
    CInput,
    CInputGroup,
    CSpinner,
    CLabel,
    CRow,
    CCol,
    CTextarea
} from '@coreui/react';
import Select from 'react-select';


const SendNotificationModal = (props) => {
    const { closeHandler, model, submitHandler, onChange, sendNotificationData, isLoading, userList } = props;
    return (
        <>
            <CModal
                size="md"
                show={model}
                onClose={closeHandler}
                closeOnBackdrop={!isLoading}
            >
                <CForm onSubmit={submitHandler}>
                    <CModalHeader closeButton>Notification</CModalHeader>
                    <CModalBody>
                        <CRow>
                            <CCol md="12">
                                <CLabel htmlFor="title">Title</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInput
                                        type="text"
                                        placeholder="Title"
                                        onChange={onChange}
                                        value={sendNotificationData.title}
                                        name="title"
                                        id="title"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="12">
                                <CLabel htmlFor="message">Message</CLabel>
                                <CInputGroup className="mb-3">
                                    <CTextarea
                                        placeholder="Message"
                                        onChange={onChange}
                                        value={sendNotificationData.message}
                                        name="message"
                                        id="message"
                                        required
                                    />
                                </CInputGroup>
                            </CCol>
                            <CCol md="12">
                                <CLabel htmlFor="selectedUsers">Users <small>(Blank in case of all)</small></CLabel>
                                <CInputGroup className="mb-3">
                                    <Select
                                        style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }} 
                                        name="selectedUsers" 
                                        id="selectedUsers"  
                                        required
                                        onChange={(e_)=>{ onChange({target: {name: 'selectedUsers', value: e_.map(user => user.value)}}) }}
                                        isMulti={true}
                                        options={userList}
                                    />
                                </CInputGroup>
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="primary" type="submit" disabled={isLoading}> Submit {isLoading ? <CSpinner color="info" size="sm" /> : ""}</CButton>
                    </CModalFooter>
                </CForm>
            </CModal>
        </>
    )
}

export default SendNotificationModal;
