import { authHeader } from '../helpers';
const BASE_URL = "https://api.masonicsprig.com/";

export const grandLodgeService = {
    getGrandLodge,
    addGrandLodge,
    updateGrandLodge,
    deleteGrandLodge
};

function getGrandLodge() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/grand-lodges`, requestOptions).then(function (data) {
        return data.json();
    });
}

function addGrandLodge(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/grand-lodges`, requestOptions).then(function (data) {
        return data.json();
    });
}

function updateGrandLodge(formdata, grandLodgeId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/grand-lodges/${grandLodgeId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function deleteGrandLodge(grandLodgeId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/grand-lodges/${grandLodgeId}`, requestOptions).then(function (data) {
        return data.json();
    });
}