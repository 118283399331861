import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton
} from '@coreui/react'

import { memberService } from "../../../store/Services"
import UploadModel from "../../../common/Model/MemberUpload";
import MemberModal from "../../../common/Model/MemberModal";
import ConfirmBox from "../../../common/Model/ConfirmBox"
import moment from 'moment'
import CIcon from '@coreui/icons-react';
import { Link } from 'react-router-dom';

const fields = [{ key: 'id', label: 'ID' }, 'firstName', 'lastName', { key: 'memberId', label: 'Member ID' }, 'grandLodge', 'localLodge', 'createdAt_', 'action'];
const defaultMemberData = { firstName: '', lastName: '', lodge: '', localLodge: '', memberId: '', grandLodge: '', id: '' };

const Reports = () => {
  const [members, setMembers] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [modal, setModal] = useState(false);
  const closeHandler = () => { setModal(false) }
  const openHandler = () => { setModal(true) }

  const [memberModal, setMemberModal] = useState(false);
  const memberModalHandler = (item) => {
    if (item) {
      setMemberData(item);
      setIsUpdate(true);
    } else {
      setMemberData(defaultMemberData)
      setIsUpdate(false);
    }
    setMemberModal(!memberModal)
  }

  const [confirmModel, setConfirmModel] = useState({ modelState: false, data: {} });
  const [memberdata, setMemberData] = useState(defaultMemberData);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await memberService.Reports();
    if(result.members){
      result.members = result.members.map(member => {
        member.createdAt_ = moment(member.createdAt).format('YYYY/MM/DD');
        return member;
      })
      setMembers(result.members);
    }
  };

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const uploadsubmitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let currentFile = selectedFiles[0];
    const formData = new FormData();
    formData.append('file', currentFile);
    memberService.MembersUpload(formData).then(async () => {
      await fetchData();
      setIsLoading(false);
      setModal(false);
    })
  }

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { id, firstName, lastName, memberId, lodge, localLodge, grandLodge } = memberdata;
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('memberId', memberId);
    // formData.append('lodge', Number(lodge) || null);
    formData.append('localLodge', localLodge);
    formData.append('grandLodge', grandLodge);

    if (id) {
      memberService.updateMember(formData, id).then(async () => {
        await fetchData();
        setIsLoading(false);
        setMemberModal(false);
      }).catch(e => {
        setIsLoading(false);
      })
    } else {
      memberService.addMember(formData).then(async () => {
        await fetchData();
        setIsLoading(false);
        setMemberModal(false);
      }).catch(e => {
        setIsLoading(false);
      })
    }

  }

  const deleteHandler = () => {
    setIsLoading(true);
    memberService.deleteMember(confirmModel.data.id).then(async () => {
      await fetchData();
      setIsLoading(false);
      setConfirmModel({ data: {}, modelState: false });
    }).catch(e => {
      setIsLoading(false);
    })
  }

  const onChange = ({ target: { name, value } }) => {
    let memberData_ = memberdata;
    setMemberData({
      ...memberData_, [name]: value
    })
  }

  return (
    <>
      <CRow>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-between">
                <CCol xs="6" lg="6">Report</CCol>
                <CCol xs="6" lg="6">
                  {memberModal && <MemberModal
                    closeHandler={memberModalHandler}
                    model={memberModal}
                    isUpdate={isUpdate}
                    submitHandler={submitHandler}
                    onChange={onChange}
                    memberData={memberdata}
                    isLoading={isLoading}
                  />}
                  <ConfirmBox
                    closeHandler={() => { setConfirmModel({ data: {}, modelState: false }) }}
                    model={confirmModel.modelState}
                    submitAction={deleteHandler}
                    headerTitle={"Delete Member"}
                    message={"Are you sure to delete it?"}
                    isLoading={isLoading}
                  />
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={members.map(member => {
                  for (let key in member) {
                    member[key] = member[key] || "";
                  }
                  return member;
                })}
                fields={fields}
                striped
                itemsPerPage={20}
                pagination
                sorter
                tableFilter
                scopedSlots={{
                  'registeredAt_':
										(item) => {
											return (
												<td className="py-2">
													{ item.user ? `${moment(item.user.createdAt).format('YYYY/MM/DD HH:mm')}` : '' }
												</td>
											)
										},
                  'action':
                    (item) => {
                      return (
                        <td className="py-2">
                          
                          <CButton
                            color="primary"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              memberModalHandler(item)
                            }}
                            className="mr-2"
                          >
                            <CIcon size={'sm'} name={'cilPencil'} />
                          </CButton>
                          <CButton
                            color="danger"
                            variant="outline"
                            size="sm"
                            className="mr-2"
                            onClick={() => {
                              setConfirmModel({ modelState: true, data: item })
                            }}
                          >
                            <CIcon size={'sm'} name={'cilTrash'} />
                            </CButton>
                          {
                            item.user ? 
                            <Link to={'/users/'+item.userId}>
                              <CButton
                                color="success"
                                variant="outline"
                                size="sm"
                                className="mr-2"
                              >
                                <CIcon size={'sm'} name={'cilCheck'} />
                              </CButton>
                            </Link> : 
                            ''
                          }
                        </td>
                      )
                    },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default Reports
