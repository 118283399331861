import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton
} from '@coreui/react'

import { inboxService } from "../../../store/Services"
import MessageModal from "../../../common/Model/MessageModal";
import MessageReplyModal from "../../../common/Model/MessageReplyModal";
import ConfirmBox from "../../../common/Model/ConfirmBox"
import moment from 'moment'

const fields = [{ key: 'id', label: 'ID' }, 'message', 'messageType', 'createAt_', 'action'];
const defaultMessageData = { message: '', messageType: '', id: '' };
const defaultReplyMessageData = { message: '', title: '', inboxMessageId: '' };

const InboxList = () => {
  const [messages, setMessages] = useState([]);

  // const [modal, setModal] = useState(false);
  // const closeHandler = () => { setModal(false) }
  // const openHandler = () => { setModal(true) }

  const [messageModal, setMessageModal] = useState(false);
  const messageModalHandler = (item) => {
    if (item) {
      setMessageData(item);
      setIsUpdate(true);
    } else {
      setMessageData(defaultMessageData)
      setIsUpdate(false);
    }
    setMessageModal(!messageModal)
  }

  const [replyModal, setReplyModal] = useState(false);
  const [replyMessageData, setReplyMessageData] = useState(defaultReplyMessageData);
  const [isReplySubmitting, setIsReplySubmitting] = useState(false);
  const replyModalHandler = (item) => {
    if(item){
      setReplyMessageData({...defaultReplyMessageData, inboxMessageId: item.id});
    }
    setReplyModal(!replyModal)
  }
  const replySubmitHandler = (e) => {
    e.preventDefault();
    setIsReplySubmitting(true);
    const {inboxMessageId, title, message} = replyMessageData;
    const formData = new FormData();
    formData.append('inboxMessageId', inboxMessageId);
    formData.append('title', title);
    formData.append('message', message);

    inboxService.submitMessageReply(formData).then(async () => {
      setIsReplySubmitting(false);
      setReplyModal(false);
    }).catch(e => {
      setIsReplySubmitting(false);
    })
  }

  const onChangeReplyMessage = ({ target: { name, value } }) => {
    let replyMessageData_ = replyMessageData;
    setReplyMessageData({
      ...replyMessageData_, [name]: value
    })
  }

  const [confirmModel, setConfirmModel] = useState({ modelState: false, data: {} });
  const [messagedata, setMessageData] = useState(defaultMessageData);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await inboxService.inboxList();
    if(result.messages){
      result.messages = result.messages.map(message => {
        message.createAt_ = moment(message.createdAt).format('YYYY/MM/DD');
        return message;
      })
    }
    setMessages(result.messages);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { id, message, messageType} = messagedata;
    const formData = new FormData();
    formData.append('message', message);
    formData.append('messageType', messageType);
  }

  // const deleteHandler = () => {
  //   setIsLoading(true);
  // }

  const onChange = ({ target: { name, value } }) => {
    let messageData_ = messagedata;
    setMessageData({
      ...messageData_, [name]: value
    })
  }

  return (
    <>
      <CRow>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow className="justify-content-between">
                <CCol xs="6" lg="6">Messages</CCol>
                <CCol xs="6" lg="6">
                  {messageModal && <MessageModal
                    closeHandler={messageModalHandler}
                    model={messageModal}
                    isUpdate={isUpdate}
                    submitHandler={submitHandler}
                    onChange={onChange}
                    messageData={messagedata}
                    isLoading={isLoading}
                  />}
                  {replyModal && <MessageReplyModal
                    closeHandler={replyModalHandler}
                    model={replyModal}
                    submitHandler={replySubmitHandler}
                    onChange={onChangeReplyMessage}
                    replyMessageData={replyMessageData}
                    isLoading={isReplySubmitting}
                  />}
                  <ConfirmBox
                    closeHandler={() => { setConfirmModel({ data: {}, modelState: false }) }}
                    model={confirmModel.modelState}
                    // submitAction={() => { setConfirmModel({ data: {}, modelState: false }) }}
                    headerTitle={"Message Detail"}
                    message={confirmModel.data.message}
                    isLoading={isLoading}
                  />
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={messages.map(message => {
                  for (let key in message) {
                    message[key] = message[key] || "";
                  }
                  return message;
                })}
                fields={fields}
                striped
                itemsPerPage={20}
                pagination
                sorter
                tableFilter
                scopedSlots={{
                  'action':
                    (item) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setConfirmModel({ modelState: true, data: item })
                            }}
                            className="mr-2"
                          >
                            View
                          </CButton>
                          <CButton
                            color="success"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              replyModalHandler(item)
                            }}
                          >
                            Reply
                          </CButton>
                        </td>
                      )
                    },
                  'messageType':
                    (item) => {
                      return (
                        <td className="py-2">
                          { item.messageTypeValue && item.messageTypeValue.messageType ? item.messageTypeValue.messageType : '' }
                        </td>
                      )
                    },
                  'message':
                    (item) => {
                      return (
                        <td className="py-2">
                          { (item.message || "").substring(0, 50) + ((item.message || "").length > 50 ? '...' : '') }
                        </td>
                      )
                    },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default InboxList
