import { authHeader } from '../helpers';
const BASE_URL = "https://api.masonicsprig.com/";

export const inboxService = {
    inboxList,
    submitMessageReply
};

function inboxList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}api/admin/inbox?offset=0&limit=100000`, requestOptions).then(function (data) {
        return data.json();
    });
}

function submitMessageReply(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: formdata
    };

    return fetch(`${BASE_URL}api/admin/send-message-reply`, requestOptions).then(function (data) {
        return data.json();
    });
}