import React, { useState, useEffect  } from 'react';
import {  useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { userActions } from '../../../store/actions';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert
} from '@coreui/react'
import CIcon from '@coreui/icons-react';

function Alert(props) {
  return <CAlert color="danger" closeButton elevation={6} variant="filled" {...props} />;
}

const Login = (props) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isInvalidAuthentication, setIsInvalidAuthentication] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  // reset login status
  useEffect(() => { 
    dispatch(userActions.logout()); 
  }, []);

  const handleSubmit =  (event) => {
    event.preventDefault();
    if(email==='' || password === ''){
      setError("Feilds are Required");
    }  
    props.dispatch(userActions.login(email, password));
  }

  return ( 
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
      {error && (
        <Alert severity="error" onClick={() => setError(null)}>
          {props.error || error}
        </Alert>
      )}
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput 
                        type="text" 
                        placeholder="Username" 
                        name="email" 
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="username" />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput 
                        type="password" 
                        placeholder="Password" 
                        name="password" 
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        autoComplete="current-password" />
                    </CInputGroup>
                    {
                      isInvalidAuthentication ? <p className="text-danger">Invalid username or password</p> : ''
                    }
                    <CRow>
                      <CCol xs="6">
                        <CButton 
                        color="primary" 
                        className="px-4" 
                        type="submit"
                        >Login</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
      loggingIn
  };
}

const connectedLoginPage = connect(mapStateToProps)(Login);
export { connectedLoginPage as Login }; 

