import { authHeader } from '../helpers';
const BASE_URL = "https://api.masonicsprig.com/";
export const userService = {
    login,
    logout,
    getAll,
    userDetail,
    submitGroupAdmin,
    getProfile,
    updateProfile,
    userCalendarEvents,
    userCalendarEventsCount,
    BASE_URL
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${BASE_URL}api/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function getProfile() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${BASE_URL}api/admin/profile`, requestOptions).then(function (data) {
        return data.json();
    });
}

function updateProfile(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: data
    };
    return fetch(`${BASE_URL}api/admin/profile`, requestOptions).then(function (data) {
        return data.json();
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function userDetail(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${BASE_URL}api/admin/users/info/${userId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function userCalendarEvents(userId, offset, limit) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${BASE_URL}api/admin/users/calendar-events/${userId}?offset=${offset}&limit=${limit}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function userCalendarEventsCount(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${BASE_URL}api/admin/users/calendar-events-count/${userId}`, requestOptions).then(function (data) {
        return data.json();
    });
}

function submitGroupAdmin(formdata) {
    const authHeader_ = authHeader();
    authHeader_['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: authHeader_,
        body: JSON.stringify(formdata)
    };

    return fetch(`${BASE_URL}api/admin/update-group-admin`, requestOptions).then(function (data) {
        return data.json();
    });
}