import React from 'react'
import {
  CModalFooter,
  CModalBody,
  CModalHeader,
  CModal,
  CButton,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CSpinner,
  CRow,
  CCol,
  CLabel
} from '@coreui/react'

import CIcon from '@coreui/icons-react';
import Select from 'react-select';


const UploadModel = (props) => {
  const {closeHandler , model, submitHandler, selectFile, changeTitle, isLoading, changeMediaType} = props;
 
    return (
      <>
        <CModal
          show={model}
          onClose={closeHandler}
        >
          <CModalHeader closeButton>Upload Media</CModalHeader>
          <CModalBody>
            <CRow>
                <CCol md="12">
                    <CLabel htmlFor="title">Title</CLabel>
                    <CInputGroup className="mb-3">
                        <CInput
                            type="text"
                            placeholder="Title"
                            onChange={changeTitle}
                            name="title"
                            id="title"
                            required
                        />
                    </CInputGroup>
                </CCol>
                <CCol md="12">
                    <CLabel htmlFor="image">Media <small>(.png, .jpg, .jpeg, .mp4, .gif, .mov, .qt)</small></CLabel>
                    <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                        <CInputGroupText>
                            <CIcon name="cil-user" />
                        </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput 
                        accept=".jpg,.png,.jpeg,.mp4,.gif,.mov,.qt"
                        type="file" 
                        placeholder="Media" 
                        onChange={selectFile}
                        name="image"
                        required
                        />
                    </CInputGroup>
                </CCol>
                <CCol md="12">
                    <CLabel htmlFor="mediaType">Media Type</CLabel>
                    <CInputGroup className="mb-3">
                        <Select
                            style={{ width: "100% !important", color: "#768192", borderColor: "#d8dbe0", borderRadius: "0.25rem", padding: "0.375rem 0.75rem" }} 
                            name="mediaType" 
                            id="mediaType"  
                            required
                            onChange={(e_)=>{ changeMediaType({target: {name: 'mediaType', value: e_.value}}) }}
                            options={[{ value: 'stock', label: 'Stock' }, { value: 'tutorial', label: 'Tutorial' }]}
                        />
                    </CInputGroup>
                </CCol>
            </CRow>

          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type ="button" onClick={submitHandler} disabled={isLoading}> Upload {isLoading ? <CSpinner color="info" size="sm" /> : ""}</CButton>
          </CModalFooter>
        </CModal>
      </>
    )
}

export default UploadModel
