import React, { useEffect, useState } from 'react'
import {
	CCard,
	CCardBody,
	CCardHeader,
	CCol,
	CDataTable,
	CRow,
	CButton
} from '@coreui/react'

import { notificationService } from "../../../store/Services"
import SendNotificationModal from "../../../common/Model/SendNotificationModal";
import moment from 'moment'

const fields = [{ key: 'id', label: 'ID' }, 'title', 'message', 'to', 'createAt_'];
const defaultNotificationData = { message: '', title: '', selectedUsers: [] };

const NotificationsList = () => {
	const [notifications, setNotifications] = useState([]);
	const [usersList, setUsersList] = useState([]);
	const [sendNotificationModal, setSendNotificationModal] = useState(false);
	const [sendNotificationData, setSendNotificationData] = useState(defaultNotificationData);
	const [isNotificationSubmitting, setIsNotificationSubmitting] = useState(false);
	const sendNotificationModalHandler = (item) => {
		if (item) {
			setSendNotificationData({ ...defaultNotificationData });
		}
		setSendNotificationModal(!sendNotificationModal)
	}

	const submitNotificationHandler = (e) => {
		e.preventDefault();
		setIsNotificationSubmitting(true);
		const { selectedUsers, title, message } = sendNotificationData;
		notificationService.sendNotification({
			title,
			message,
			selectedUserIds: selectedUsers
		}).then(async () => {
			fetchData();
			setIsNotificationSubmitting(false);
			setSendNotificationModal(false);
		}).catch(e => {
			setIsNotificationSubmitting(false);
		})
	}

	const onChangeNotificationData = ({ target: { name, value } }) => {
		let sendNotificationData_ = sendNotificationData;
		setSendNotificationData({
			...sendNotificationData_, [name]: value
		})
	}

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const result = await notificationService.notificationsList();
		if (result.notifications) {
			const usersData = await notificationService.getActiveUsers();
			if (usersData.users) {
				let users = usersData.users.map(user => ({label: (user.firstName+" "+user.lastName+" "+`(${user.email})`), value: user.userId}));
				setUsersList(users);
			}
			result.notifications = result.notifications.rows.map(message => {
				message.createAt_ = moment(message.createdAt).format('YYYY/MM/DD');
				return message;
			})
		}
		setNotifications(result.notifications);
	};

	return (
		<>
			<CRow>
				<CCol xs="12" lg="12">
					<CCard>
						<CCardHeader>
							<CRow className="justify-content-between">
								<CCol xs="6" lg="6">Notifications</CCol>
								<CCol xs="6" lg="6">
								<div className="text-right">
                    <CButton
                      color="primary"
                      onClick={() => { setSendNotificationModal(!sendNotificationModal) }}
                      className="px-4" >
                      Send
                    </CButton>
                  </div>
									{sendNotificationModal && <SendNotificationModal
										closeHandler={sendNotificationModalHandler}
										model={sendNotificationModal}
										submitHandler={submitNotificationHandler}
										onChange={onChangeNotificationData}
										sendNotificationData={sendNotificationData}
										isLoading={isNotificationSubmitting}
										userList={usersList}
									/>}
								</CCol>
							</CRow>
						</CCardHeader>
						<CCardBody>
							<CDataTable
								items={notifications.map(notification => {
									for (let key in notification) {
										notification[key] = notification[key] || "";
									}
									return notification;
								})}
								fields={fields}
								striped
								itemsPerPage={20}
								pagination
								sorter
								tableFilter
								scopedSlots={{
									'message':
										(item) => {
											return (
												<td className="py-2">
													{ (item.message || "").substring(0, 50) + ((item.message || "").length > 50 ? '...' : '')}
												</td>
											)
										},
										'to':
										(item) => {
											return (
												<td className="py-2">
													{ item.user ? `${item.user.firstName} ${item.user.lastName}` : '' }
												</td>
											)
										},
								}}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</>
	)
}

export default NotificationsList
